<template>
	<c-dialog ref="dialog" title="选择品牌" width="800" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="品牌名称">
				<c-input name="brand_name"></c-input>
			</m-search-item>
			<m-search-item>
				<c-checkbox value="1" @change="changeCustomer">只看当前客户</c-checkbox>
			</m-search-item>
		</m-search>
		<c-table ref="table"  height="500">
			<c-table-column
				type="selector"
				width="20"
				:multiple="!!multiple"
				:selected="row => row.data.checked"
			></c-table-column>

			<c-table-column
				label="品牌名称"
				width="120"
				name="brand_name"
			></c-table-column>

			<c-table-column
				label="备注"
				width="200"
				name="remark"
			>
				<template #default="{data}">
					<span v-if="data.remark">{{data.remark}}</span>
					<span v-else>--</span>
				</template>
			</c-table-column>

			<c-table-column
				label="LOGO"
				name="logos"
				width="200"
			>
				<template v-slot="{data}">
					<div  class="flex-center-cross">
						<c-file media v-for="(logo, j) in data.logos"  :src="logo" style="width:40px;height:40px;"></c-file>
					</div>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
export default {
	props: {
		multiple: Boolean
	},
	data() {
		return {
			current_customer:1,//仅展示当前客户数据
			customer_id:''
		}
	},
	methods: {
		changeCustomer(data) {
			if (data){
				this.current_customer = 1;
			}else {
				this.current_customer = 0;
			}
		},
		searchHandle(data) {
			if(!data){
				data = {};
			}
			if (this.current_customer == 1){
				data.customer_id = this.customer_id;
			}else {
				data.customer_id = '';
			}
			this.$refs.table.load({
				url: '/dic/brand/list',
				data
			});
		},
		open(customer_id) {
			this.customer_id = customer_id||'';
			this.$refs.dialog.open();
			this.searchHandle();
		},

		resolve(stop) {
			var data = this.$refs.table.getRow('selected');
			if (!data.length) {
				stop();
				this.$message({
					message: '请选择品牌'
				});
				return;
			}
			this.$emit('resolve', Array.from(data, item => item.data));
		}
	}
}
</script>