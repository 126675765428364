<template>
	<c-dialog ref="dialog" title="选择产品" width="650" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="产品名称">
				<c-input name="product_name"></c-input>
			</m-search-item>
<!--			<m-search-item label="产品编码">
				<c-input name="product_code"></c-input>
			</m-search-item>-->
			<m-search-item label="产品颜色">
				<c-input name="product_spec"></c-input>
			</m-search-item>
			<m-search-item label="客户货号" v-if="customer">
				<c-input name="customer_product_code"></c-input>
			</m-search-item>
			<m-search-item v-if="customer">
				<c-checkbox value="1" @change="changeCustomer">只看当前客户</c-checkbox>
			</m-search-item>
		</m-search>
		<c-table ref="table" height="400" >
			<c-table-column
				type="selector"
				:multiple="!!multiple"
			></c-table-column>
			
<!--			<c-table-column
				label="产品编码"
				width="100"
				name="product_code"
			></c-table-column>-->
			
			<c-table-column
				label="客户货号"
				width="100"
				name="customer_product_code"
				v-if="customer"
			></c-table-column>
			
			<c-table-column
				label="产品图片/名称"
				width="220"
				name="product_img"
			>
				<template #default="{data}">
				    <div class="flex-center-cross">
				        <c-file v-if="data.product_img" media :src="data.product_img" style="margin-right: 18px;width:40px;height:40px;"></c-file>
						<span>{{data.product_name}}</span>
				    </div>
				</template>
			</c-table-column>

			<c-table-column
				label="颜色"
				width="100"
				name="product_spec"
			></c-table-column>

			<c-table-column
				label="单位"
				width="50"
				name="unit"
			></c-table-column>
			<!-- <c-table-column
				label="所属类目"
				width="170"
				name="product_type_name"
			></c-table-column> -->
			
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean,
			customer: Boolean
		},
		data() {
			return {
				current_customer:1,//仅展示当前客户数据
				customer_id:'',
				not_customer_id:'',
				not_product_ids:[]
			}
		},
		methods: {
			changeCustomer(data) {
				if (data){
					this.current_customer = 1;
				}else {
					this.current_customer = 2;
				}
			},
			//普通产品弹框
			open(customer_id,not_product_ids) {
				this.$refs.search.clear();
				this.not_customer_id = '';
				this.$refs.dialog.open();
				this.customer_id = customer_id;
				this.not_product_ids = not_product_ids||0;
				this.searchHandle();
			},
			//排除客户选择产品弹框
			openExists(not_customer_id) {
				this.$refs.search.clear();
				this.customer_id = '';
				this.$refs.dialog.open();
				this.not_customer_id = not_customer_id;
				this.searchHandle();
			},
			
			//搜索产品
			searchHandle(data){
				if (!data){
					data = {};
				}
				data.customer_id = this.customer_id;
				data.current_customer = this.current_customer;
				data.type = 1;
				data.not_customer_id = this.not_customer_id;
				data.material_ids = this.not_product_ids;
				this.$refs.table.load({
					url: '/product/list',
					data
				});
			},
			
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择产品'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>