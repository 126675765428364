<template>
	<c-dialog ref="dialog" title="选择客户" width="900" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="客户名称">
				<c-input name="name"></c-input>
			</m-search-item>
			<m-search-item label="客户编码">
				<c-input name="code"></c-input>
			</m-search-item>
		</m-search>
		<c-table ref="table" height="400">
			<c-table-column
				type="selector"
				:multiple="!!multiple"
                :selected="row => row.data.checked"
			></c-table-column>

			<c-table-column
				label="客户名称"
				width="150"
				name="name"
			></c-table-column>

			<c-table-column
				label="客户编码"
				width="100"
				name="code"
			></c-table-column>

			<c-table-column
				label="客户联系人"
				width="100"
				name="contact_name"
			></c-table-column>

			<c-table-column
				label="联系方式"
				width="140"
				name="phone"
			></c-table-column>

			<c-table-column
				label="地址"
				width="170"
				name="address"
			></c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean
		},
		
		methods: {
			open(brand_id) {
				this.$refs.dialog.open();
				this.brand_id = brand_id||0;
				this.$refs.search.search();
			},
			//排除客户选择产品弹框
			openExists(brand_id) {
				this.$refs.dialog.open();
				this.not_brand_id = brand_id;
				this.$refs.search.search();
			},
			//搜索产品
			searchHandle(data){
				data.type = 1;
				data.not_brand_id = this.not_brand_id;
				data.brand_id = this.brand_id;
				this.$refs.table.load({
					url: '/customer/list',
					data
				});
			},
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择客户'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>