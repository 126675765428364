<template>
	<c-dialog ref="dialog" :title="this.title" width="600" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
			<m-search-item label="备注">
				<c-input name="remark"></c-input>
			</m-search-item>
		</m-search>
		<c-table ref="table"  height="500">
			<c-table-column
				type="selector"
				width="20"
				:multiple="!!multiple"
				:selected="row => row.data.checked"
			></c-table-column>

			<c-table-column
				label="LOGO"
				width="60"
				name="logo"
			>
				<template #default="{data}">
					<div class="flex-center-cross">
						<c-file v-if="data.logo" media :src="data.logo" style="width:40px;height:40px;"></c-file>
					</div>
				</template>
			</c-table-column>

			<c-table-column
				label="描述"
				width="220"
				name="remark"
			></c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
export default {
	props: {
		multiple: Boolean
	},
	data() {
		return {
			brand_id:'',
			title:''
		}
	},
	methods: {
		searchHandle(data) {
			if(!data){
				data = {};
			}
			data.brand_id = this.brand_id;
			this.$refs.table.load({
				url: '/dic/brand/logo/list',
				data
			});
		},

		open(brand_id,brand_name) {
			this.brand_id = brand_id||'';
			this.title = '选择品牌【'+brand_name+'】LOGO';
			this.$refs.dialog.open();
			this.searchHandle();
		},

		resolve(stop) {
			var data = this.$refs.table.getRow('selected');
			if (!data.length) {
				stop();
				this.$message({
					message: '请选择品牌LOGO'
				});
				return;
			}
			this.$emit('resolve', Array.from(data, item => item.data));
		}
	}
}
</script>