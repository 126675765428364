<template>
	<page>
		<module>
			<c-form ref="form" style="max-width: 1100px;" >
				<c-title>基本信息</c-title>
				<c-form  column="2" unit-width="95%">
					<c-form-item label="下单客户" required>
						<c-input v-model="customerId" type="hidden" name="customer_id"></c-input>
						<div class="flex-center">
							<c-input class="flex-grow" name="customer_name" maxlength="50" readonly disabled></c-input>
							<c-button color="sys" style="margin-left: 10px;" @click="$refs.customerSelector.open()">选择客户</c-button>
						</div>
					</c-form-item>
					<c-form-item label="交货日期">
						<c-datetime type="date" name="delivery_schedule_date"></c-datetime>
					</c-form-item>
					<c-form-item label="产品" required>
						<c-input type="hidden" name="product_id"></c-input>
						<c-input type="hidden" name="product_spec"></c-input>
						<div class="flex-center">
							<c-input class="flex-grow" name="product_name" maxlength="50" readonly disabled></c-input>
							<c-button color="sys" style="margin-left: 10px;" @click="selectProduct">选择产品</c-button>
						</div>
					</c-form-item>
					<c-form-item label="客户货号">
						<div class="flex-center">
							<c-input class="flex-grow" name="customer_product_code" maxlength="50" :disabled="isHaveCustomerProductCode"></c-input>
							<c-button color="sys" style="margin-left: 10px;" @click="editCustomerProductCode" v-if="isHaveCustomerProductCode">修改</c-button>
						</div>
						
					</c-form-item>
					<c-form-item  label="品牌">
						<c-input type="hidden" name="brand_id"></c-input>
						<div class="flex-center">
							<c-input class="flex-grow" name="brand_name" maxlength="50" readonly disabled></c-input>
							<c-button v-if="auth('b_brand_edit')" color="sys" style="margin-left: 10px;" @click="selectBrand">选择品牌</c-button>
							<c-button v-if="auth('b_brand_edit')" color="main" style="margin-left: 10px;" @click="createBrand">新建品牌</c-button>
						</div>
					</c-form-item>
					<c-form-item label="品牌LOGO">
						<div class="flex-center">
							<c-input type="hidden" name="brand_logo"></c-input>
							<div class="flex-grow flex-center-cross">
								<c-file v-if="brand_logo" media :src="brand_logo" style="width:40px;height:40px;"></c-file>
							</div>
							<c-button v-if="auth('b_brand_edit')" color="sys" style="margin-left: 10px;" @click="selectBrandLogo">选择LOGO</c-button>
							<c-button v-if="auth('b_brand_edit')" color="main" style="margin-left: 10px;" @click="createBrandLogo">新建LOGO</c-button>
						</div>
					</c-form-item>
					<c-form-item label="计划生产数量" required>
						<c-input type="number" decimal="2" name="product_count" range="0.01,999999"></c-input>
					</c-form-item>
					<c-form-item label="商品单价">
						<c-input type="number" decimal="2" name="product_unit_price" range="0.01,999999"></c-input>
					</c-form-item>
				</c-form>
				<c-title v-show="this.paramList.length > 0||this.productParamList.length > 0">产品信息</c-title>
				<!--展示商品类目属性列表-->
				<c-form-item v-show="this.paramList.length > 0" label="" width="1000">
					<div class="area">
						<c-form ref="paramForm" column="2" name="production_json" style="padding: 0;">
							<c-form-item v-for="(item, i) in paramList" :key="i" :label="item.parameter_name" :required="item.required_flag == 2" style="margin-bottom: 16px;">
								<c-input v-if="item.parameter_type == 'text'" :name="item.parameter_name"></c-input>
								<c-select v-else-if="item.parameter_type == 'select'" :name="item.parameter_name" :options="item.options"></c-select>
								<c-radio-group v-else-if="item.parameter_type == 'radio'" :name="item.parameter_name" :options="item.options"></c-radio-group>
								<c-checkbox-group v-else-if="item.parameter_type == 'checkbox'" :name="item.parameter_name" :options="item.options"></c-checkbox-group>
							</c-form-item>
						</c-form>
					</div>
				</c-form-item>
				<!--展示产品参数列表-->
				<c-form-item v-show="this.productParamList.length > 0" label="" width="1000">
					<div class="area">
						<c-form ref="productParamForm" column="2" name="product_json" style="padding: 0;">
							<c-form-item v-for="(item, i) in productParamList" :key="i" :label="item.name+(item.select_type==2?'(可多选)':'')" style="margin-bottom: 16px;" required>
								<c-select :name="item.name" :options="item.options" :value="item.value" :multiple="item.select_type == 2"></c-select>
							</c-form-item>
						</c-form>
					</div>
				</c-form-item>
			</c-form>

			<template slot="button">
				<c-button color="main" size="form" @click="submit(1)">确认订单</c-button>
				<c-button size="form" @click="submit(-2)">暂存草稿</c-button>
			</template>
		</module>
		<c-dialog ref="brandEdit" :title="'客户【'+this.customerName+'】新建品牌'" width="600" @resolve="submitBrand">
			<c-form ref="brandForm" unit-width="100%">
				<c-form-item label="品牌名称" required>
					<c-input name="brand_name"></c-input>
				</c-form-item>
				<c-form-item label="备注">
					<c-input type="textarea" name="remark"></c-input>
				</c-form-item>
			</c-form>
		</c-dialog>
		<!--新增、编辑品牌LOGO 弹框-->
		<c-dialog ref="logoEdit" :title="'品牌【'+brand_name+'】新建LOGO'" width="500" @resolve="submitBrandLogo">
			<c-form ref="logoForm" unit-width="100%">
				<c-form-item label="LOGO" width="800" required>
					<c-upload-file name="logo" media type="image" tip="请上传LOGO图片，建议尺寸160×160px"></c-upload-file>
				</c-form-item>
				<c-form-item label="备注">
					<c-input type="textarea" name="remark"></c-input>
				</c-form-item>
			</c-form>
		</c-dialog>
		<!--客户弹窗-->
		<customer-selector ref="customerSelector" @resolve="selectCustomerHandle"></customer-selector>
		<!--产品弹框-->
		<product-selector ref="productSelector" customer @resolve="selectProductHandle"></product-selector>
		<!--品牌弹框-->
		<brand-selector ref="brandSelector" @resolve="selectBrandHandle"></brand-selector>
		<!--品牌LOGO弹框-->
		<logo-selector ref="logoSelector" @resolve="selectBrandLogoHandle"></logo-selector>
	</page>
</template>

<script>
import customerSelector from '@/components/service/customer_selector.vue'  //客户选择框
import productSelector from '@/components/service/product_selector.vue'//商品选择框
import brandSelector from '@/components/service/brand_selector.vue'//品牌选择框
import logoSelector from '@/components/service/logo_selector.vue'//品牌LOGO选择框
import {mapState} from 'vuex'
export default {
	name: 'm_edit_order',
	props: {
		id: String
	},
	components: {
		customerSelector,productSelector,brandSelector,logoSelector
	},
	data() {
		return {
			customerId: '',
			customerName: '',
			paramList:[],
			colorList:[],
			customColorList:[],
			brandOptions:[],
			brand_id:'',
			brand_name:'',
			brand_logo:'',
			currentSpec: [],
			productParamList: [],
			isHaveCustomerProductCode: true
		}
	},
	computed: {
	},

	watch: {
		id() {
			this.$refs.form.clear();
			this.currentSpec = [];
			this.paramList = [];
			this.colorList = [];
			this.sizeData = [];
			if (this.id) {
				this.getOrderDetail();
			}
		},
		
		customerId(val, old) {
			if (old && old != val) {
				this.$refs.form.set({
					product_id: '',
					product_name: ''
				});
				this.paramList = [];
			}
		},
	},

	mounted() {
		if (this.id) {
			this.getOrderDetail();
		}
	},

	methods: {
		//查询订单详情
		getOrderDetail() {
			this.request({
				url: '/order/detail',
				data: {
					id:this.id
				},
				loading: true,
				success: async data => {
					if (data.product_spec){
						data.product_name = (data.product_name+"/"+data.product_spec);
					}
					this.$refs.form.set(data);
					this.getProductParams(data.product_type_id, data.production_json, data.product_id, data.product_json);
					this.customerId = data.customer_id;
					this.customerName = data.customer_name;
					this.brand_id = data.brand_id;
					this.brand_name = data.brand_name;
					this.brand_logo = data.brand_logo;
				}
			});
		},
		//加载当前商品分类支持的属性列表
		getProductParams(type, formData, product_id, product_json) {
			//商品参数
			this.request({
				url: '/product/detail',
				data: {
					id: product_id
				},
				success: data => {
					this.$refs.productParamForm.clear();
					this.productParamList = JSON.parse(data.product_json);
					this.productParamList.forEach(item => {
						//自定义选项
						if(item.type == -1){
							var opList = item.value.split(",");
							item.options = opList.map(item => {
								return {
									name: item,
									value: item
								}
							})
							if(opList.length != 1 ){
								item.value = null;
							}
						//普通字典选项
						}else{
							item.options = item.value.map(value => {
								return {
									name: value,
									value: value
								}
							});
							if(item.value.length !=1 ){
								item.value = null;
							}
						}
					})
					
					if (product_json) {
						this.$nextTick(() => {
							this.$refs.productParamForm.set(product_json);
						})
					}
				}
			});
			
			//分类属性
			this.request({
				url: '/product_param/common/list',
				data: {
					product_type_id: type
				},
				success: data => {
					this.$refs.paramForm.clear();
					data.forEach(item => {
						if (item.parameter_values) {
							item.options = item.parameter_values.map(value => {
								return {
									name: value,
									value: value
								}
							})
						}
					})
					this.paramList = data;
					
					if (formData) {
						this.$nextTick(() => {
							this.$refs.paramForm.set(formData);
						})
					}
				}
			});
		},
		//选择客户回调
		selectCustomerHandle(data) {
			var obj = data[0];
			this.$refs.form.set({
				customer_id: obj.id,
				customer_name: obj.name
			});
			this.customerId = obj.id;
			this.customerName = obj.name;
		},
		//选择产品
		selectProduct() {
			if (!this.customerId) {
				this.$message({
					message: '请先选择下单客户'
				});
				return;
			}
			this.$refs.productSelector.open(this.customerId);
		},
		//选择产品回调
		selectProductHandle(data) {
			var obj = data[0];
			if(obj.customer_product_code != null){
				this.isHaveCustomerProductCode = true;
			}else{
				this.isHaveCustomerProductCode = false;
			}
			this.$refs.form.set({
				product_id: obj.id,
				product_spec: obj.product_spec,
				product_name: obj.product_name+"/"+obj.product_spec,
				customer_product_code: obj.customer_product_code||''
			});
			this.getProductParams(obj.product_type_id,null,obj.id,null);
		},
		//修改客户货号
		editCustomerProductCode(){
			this.isHaveCustomerProductCode = false;
		},
		//选择品牌
		selectBrand() {
			if (!this.customerId) {
				this.$message({
					message: '请先选择下单客户'
				});
				return;
			}
			this.$refs.brandSelector.open(this.customerId);
		},
		//选择品牌回调
		selectBrandHandle(data) {
			var obj = data[0];
			this.$refs.form.set({
				brand_id: obj.id,
				brand_name: obj.brand_name
			});
			this.brand_id = obj.id;
			this.brand_name = obj.brand_name;
			if (obj.logos && obj.logos.length == 1){
				this.brand_logo = obj.logos[0];
				this.$refs.form.set({
					brand_logo: this.brand_logo
				});
			}
		},
		//选择品牌LOGO
		selectBrandLogo() {
			if (!this.brand_id) {
				this.$message({
					message: '请先选择品牌'
				});
				return;
			}
			this.$refs.logoSelector.open(this.brand_id,this.brand_name);
		},
		//选择品牌LOGO回调
		selectBrandLogoHandle(data) {
			var obj = data[0];
			this.$refs.form.set({
				brand_logo: obj.logo,
			});
			this.brand_logo = obj.logo;
		},
		//创建品牌
		createBrand() {
			if (!this.customerId) {
				this.$message({
					message: '请先选择下单客户'
				});
				return;
			}
			this.$refs.brandForm.clear();
			this.$refs.brandForm.set({});
			this.$refs.brandEdit.open();
		},
		//提交品牌
		submitBrand(stop,next) {
			stop();
			this.$refs.brandForm.submit({
				url: '/dic/brand/add',
				rule: {
					brand_name: '请填写品牌名'
				},
				dataFormatter: data => {
					data.customer_id = this.customerId;
					return data;
				},
				success: (data, res) => {
					next();
					this.$message({
						message: '新建品牌成功',
						type: 'success'
					});
					//form赋值
					this.brand_id = res.brand_id;
					this.brand_name = res.brand_name;
					this.$refs.form.set({
						brand_id: res.brand_id,
						brand_name: res.brand_name
					});
				}
			});
		},
		//创建品牌LOGO
		createBrandLogo() {
			if (!this.brand_id) {
				this.$message({
					message: '请先选择品牌'
				});
				return;
			}
			this.$refs.logoForm.clear();
			this.$refs.logoForm.set({});
			this.$refs.logoEdit.open();
		},
		//提交品牌LOGO
		submitBrandLogo(stop,next) {
			stop();
			this.$refs.logoForm.submit({
				url: '/dic/brand/logo/add',
				rule: {
					logo: '请上传LOGO'
				},
				dataFormatter: data => {
					data.brand_id = this.brand_id;
					return data;
				},
				success: (data, res) => {
					next();
					this.$message({
						message: '新建品牌LOGO成功',
						type: 'success'
					});
					//form赋值
					this.brand_logo = res.logo;
					this.$refs.form.set({
						brand_logo: res.logo
					});
				}
			});
		},
		//提交数据
		submit(state) {
			this.$refs.form.submit({
				url: '/order/edit',
				rule: {
					customer_id: '请选择下单客户',
					product_id: '请选择下单产品',
					product_count: '请填写计划生产数量',
					/*product_unit_price: '请填写商品单价',*/
					production_json: [{
						type: 'function',
						fun: value => {
							return !this.paramList.find(item => {
								return item.required_flag == 2 && !value[item.parameter_name];
							});
						},
						message: '请填写带星号的类目属性'
					}],
					product_json: [{
						type: 'function',
						fun: value => {
							return !this.productParamList.find(item => {
								return !value[item.name];
							});
						},
						message: '请选择带星号产品参数'
					}]
				},
				
				dataFormatter: data => {
					for (let key in data.production_json) {
						if (data.production_json[key] && typeof data.production_json[key] == 'object') {
							data.production_json[key] = data.production_json[key].join(',');
						}
					}
					
					for (let key in data.product_json) {
						if (data.product_json[key] && typeof data.product_json[key] == 'object') {
							data.product_json[key] = data.product_json[key].join(',');
						}
					}
					
					data.product_list = [{
						"product_spec":data.product_spec,
						"product_count":data.product_count,
						"product_unit_price":data.product_unit_price
					}];

					data.id = this.id;
					data.state = state;
					console.log(data);
					return data;
				},
				
				success: (data, obj , message) => {
					this.$message({
						type: 'success',
						message: message
					});
					this.$bus.$emit('orderList.update',{});
					this.redirectTo('/order_list');
				}
			});
		}
	}
}
</script>

<style lang="stylus" scoped>
	.box {
		box-sizing: border-box;
		border: 1px dotted #ddd;
		padding: 14px;
	}
	
	.area {
		position: relative;
		padding: 20px 20px 0;
		background: #f9f8fb;
	}
	
	.spec {
		&:nth-child(2) {
			margin-left: 10px;
		}
		
		&-head {
			line-height: 36px;
			font-size: 14px;
			color: #666;
			
			&:nth-last-child(1) {
				padding-bottom: 10px;
			}
		}
		
		&-item {
			&:nth-child(n+2) {
				margin-top: 6px;
			}
		}
		
		&-price {
			label {
				padding: 0 5px;
				line-height: 24px;
				font-size: 12px;
			}
			
			&:nth-child(n+2) {
				margin-left: 10px;
			}
			
			.c-input {
				width: 80px;
				height: 30px;
				
				>>> input {
					text-align: center;
				}
			}
		}
		
		&-total {
			line-height: 50px;
			font-size: 13px;
			
			span {
				margin-right: 20px;
			}
		}
		
		&-remove {
			position: absolute;
			right: 0;
			top: 0;
			width: 24px;
			line-height: 24px;
			font-size: 12px;
			text-align: center;
			color: #fff;
			background: #aaa;
			
			&:hover {
				background: #999;
			}
		}
		
		.size {
			&:nth-child(n+2) {
				margin-left: 10px;
			}
			
			&-ipt {
				width: 44px;
				height: 30px;
			}
			
			label {
				padding: 0 5px;
				line-height: 24px;
				font-size: 12px;
			}
			
			>>> input {
				text-align: center;
			}
		}
	}
</style>